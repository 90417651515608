import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/kulak.technology/midival-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "midival"
    }}>{`@midival`}</h1>
    <h2 {...{
      "id": "installing-midivalcore"
    }}>{`Installing @midival/core`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm install --save @midival/core
# or
yarn add @midival/core
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      